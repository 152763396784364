<template>
  <router-view></router-view>
</template>

<script>
import { useUser } from 'vue-clerk'
import { useSharedStore } from '../shared/store.js'
export default {
  data() {
    return {
      isLoaded: null, user: null, isSignedIn: null
    }
  },
  watch: {
    isLoaded() {
      if (this.isSignedIn && this.user) {
        useSharedStore().isAdmin = !!this.user.organizationMemberships[0]?.role
        useSharedStore().user = this.user
        console.log(useSharedStore().isAdmin);
      }
    }
  },
  mounted() {
    const { isLoaded, user, isSignedIn } = useUser()
    this.isLoaded = isLoaded
    this.user = user
    this.isSignedIn = isSignedIn
  },

}
</script>
