import './style/main.css'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router.js'

import { clerkPlugin } from 'vue-clerk'
import { dark } from '@clerk/themes'

const pinia = createPinia()
const app = createApp(App)
console.log(process.env.VITE_CLERK_PUBLISHABLE_KEY)
app.use(pinia)
app.use(router)
app.use(clerkPlugin, {
  publishableKey: process.env.VITE_CLERK_PUBLISHABLE_KEY,
  appearance: dark,
})
app.mount('#app')

// eslint-disable-next-line unicorn/prefer-module, no-undef
const requireComponent = require.context('../shared/components', true, /\.vue$/)
for (const fileName of requireComponent.keys()) {
  const componentConfig = requireComponent(fileName)
  const componentName = fileName.split('/').pop().split('.')[0]
  app.component(componentName, componentConfig.default || componentConfig)
}
