import instance from '@/config/api.js'
import { useSharedStore } from '@/shared/store.js'

class API {
  async getLists() {
    try {
      const c = await instance.get('getLists', {})
      return c.data
    } catch (error) {
      useSharedStore().errorHandler(
        error,
        null,
        `url "/getTools" does not work`
      )
      return 'wtf'
    }
  }

  async getTools() {
    try {
      const tools = await instance.get('getTools')
      return tools.data
    } catch (error) {
      useSharedStore().errorHandler(error, null, 'url /getTools does not work')
      return []
    }
  }

  async getCategories() {
    try {
      const categories = await instance.get('getCategories')
      return categories.data
    } catch (error) {
      useSharedStore().errorHandler(
        error,
        null,
        'url /getCategories does not work'
      )
      return []
    }
  }

  async getDates() {
    try {
      const dates = await instance.get('getDates')
      return dates.data
    } catch (error) {
      useSharedStore().errorHandler(error, null, 'url /getDates does not work')
      return []
    }
  }

  async addTool(nameTool, categoryId) {
    try {
      console.log(nameTool, categoryId)
      await instance.post('addTool', { name: nameTool, category: categoryId })
    } catch (error) {
      useSharedStore().errorHandler(error, null, 'url /addTool does not work')
      return []
    }
  }

  async addCategory(name) {
    try {
      await instance.post('addCategory', { name })
    } catch (error) {
      useSharedStore().errorHandler(
        error,
        null,
        'url /addCategory does not work'
      )
      return []
    }
  }

  async getCountOfCurrentItem(idTool, idJobBoardRegion, signal) {
    try {
      const dates = await instance.get('getCountOfCurrentItem', {
        signal,
        params: { idTool, idJobBoardRegion },
      })
      return dates.data
    } catch (error) {
      useSharedStore().errorHandler(
        error,
        null,
        'url /getCountOfCurrentItem does not work'
      )
    }
  }

  async getJobBoardsRegions() {
    try {
      const response = await instance.get('getJobBoardsRegions')
      return response.data
    } catch (error) {
      useSharedStore().errorHandler(
        error,
        null,
        'url /getJobBoardsRegions does not work'
      )
      return []
    }
  }

  async getLastCountOfAllItems(idDate, idJobBoardRegion) {
    try {
      const response = await instance.get('getOneCountForAllTools', {
        params: { idDate, idJobBoardRegion },
      })
      return response.data
    } catch (error) {
      useSharedStore().errorHandler(
        error,
        null,
        'url /getLastCountOfAllItems does not work'
      )
    }
    return 'wtf'
  }
}

export default new API()
